import React from 'react'
import classnames from 'classnames'
import styles from './Icon.module.scss'
import * as icons from './icons'

export interface IconProps {
  className?: string
  name: keyof typeof icons
  style?: React.CSSProperties
  size?: 16 | 20 | 24 | 28 | 32
  fillColor?: string | boolean
  width?: number
  height?: number
  onClick?(): void
}

export const Icon: React.FC<IconProps> = ({ className, name, style, size, width, height, fillColor, onClick }) => {
  const IconComponent = icons[name]

  return (
    <span
      style={style}
      className={classnames(
        styles.icon,
        {
          [styles.size16]: size === 16,
          [styles.size20]: size === 20,
          [styles.size24]: size === 24,
          [styles.size28]: size === 28,
          [styles.size32]: size === 32,
          [styles.clickable]: !!onClick,
          [styles.fillColor]: !!fillColor,
        },
        className,
      )}
      onClick={onClick}
    >
      <IconComponent
        width={width}
        height={height}
        style={{ color: typeof fillColor === 'string' ? fillColor : undefined }}
      />
    </span>
  )
}
