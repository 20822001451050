import axios from 'axios'
import type { ExportPdfPayload } from '@/pages/api/export-pdf/types'
import type {
  PropagandaPostPayload,
  PropagandaReadFilePayload,
  PropagandaResultsReadFileResponse,
  PropagandaResultsResponse,
} from '@/pages/api/propaganda/types'
import { auth } from './api.auth'
import { electionsIntegrity } from './api.elections-integrity'
import { news } from './api.news'
import { reports } from './api.reports'
import { API, AxiosError, instance, isAxiosError, isCancel } from './instance'

export type { AxiosError }

type AppVersion = {
  buildId: string
  timestamp: string
  GITHUB_SHA: string
  GITHUB_RUN_ID: string
  GITHUB_RUN_NUMBER: string
}

export const ApiInternal = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,

  isAxiosError,
  isCancel,

  appVersion: async () => {
    const data = await axios
      .get<AppVersion>(`/version.txt?timestamp=${Date.now()}`)
      .then((resp) => resp.data)
      .catch((err) => ({}))

    const buildId = typeof window !== 'undefined' ? window.__NEXT_DATA__?.buildId : ''
    return { ...data, buildId }
  },

  auth,
  news,
  reports,
  electionsIntegrity,

  exportPdf: async ({ timeZone, filename, ...data }: ExportPdfPayload & { filename?: string }) => {
    timeZone = timeZone || Intl?.DateTimeFormat().resolvedOptions().timeZone || 'UTC'
    const fileNamePrefix = filename ? `${filename.split('.')[0]}_` : ''
    return instance
      .post(
        API.EXPORT_PDF,
        { timeZone, ...data },
        {
          responseType: 'arraybuffer',
        },
      )
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileNamePrefix}results.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  },

  propaganda: {
    detectPropaganda: (values: PropagandaPostPayload) =>
      instance.post<PropagandaResultsResponse>(API.PROPAGANDA.DETECT, values),

    readFile: (data: { file: File }) => {
      const formData: PropagandaReadFilePayload = new FormData()
      formData.append('file', data.file)
      return instance.post<PropagandaResultsReadFileResponse>(API.PROPAGANDA.READ_FILE, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  },
}
