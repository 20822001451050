import * as Sentry from '@sentry/nextjs'
import axios, { AxiosError, isAxiosError, isCancel } from 'axios'

export type { AxiosError }

export { isAxiosError, isCancel }

export const API = {
  CURRENT_USER: '/current-user',
  EXPORT_PDF: '/export-pdf',
  AUTH: {
    SIGN_UP: '/auth/sign-up',
    CONFIRM_CODE: '/auth/confirmation-code',
    RESEND_CODE: '/auth/resend-confirmation',
    CODE_DESTINATION: '/auth/get-code-destination',
    RESET_PASSWORD: '/auth/reset-password',
    CHANGE_PASSWORD: '/auth/change-password',
    FORCE_CHANGE_PASSWORD: '/auth/force-change-password',
  },
  DEMO: {
    GET_DATASETS: '/demo/get-datasets',
    GET_MESSAGES: '/demo/get-messages',
  },
  PROPAGANDA: {
    DETECT: '/propaganda/detect',
    READ_FILE: '/propaganda/read-file-content',
  },
  NEWS: {
    STATUS: '/susp-news/status',
    CHECK_TEXT: '/susp-news/check-text',
    READ_FILE: '/susp-news/read-file-content',
  },
  PROMPT_RUNNER: {
    PROMPTS: '/prompt-runner/prompts',
    UPLOAD_FILES: '/prompt-runner/upload-files',
    RUN_BATCH: '/prompt-runner/run-batch',
    GET_BATCHES: '/prompt-runner/batches',
    GET_BATCH_STATUS: '/prompt-runner/get-batch-status',
    DELETE_PROMPT: '/prompt-runner/delete-prompt',
  },
  CHART_SERVER: {
    INDEX: '/chart-api',
  },
  REPORTS: {
    LIST: '/reports/list',
    DOWNLOAD_URL: '/reports/download-url',
    THUMBNAIL: '/reports/get-thumbnail',
  },
  ELECTIONS_INTEGRITY: {
    LIST: '/elections-integrity/list',
    UPLOAD_FILE: '/elections-integrity/upload-file',
    CREATE_VIOLATION: '/elections-integrity/create-violation',
    REPORTS: '/elections-integrity/analytics-reports',
  },
}

export const readAxiosError = (error: AxiosError | Error) => {
  const data = isAxiosError(error) ? (error.response?.data as { error?: string; message?: string }) : {}
  return data?.error || data?.message || error.message
}

export const instance = axios.create({
  baseURL: '/api',
  timeout: 60 * 1000,
})

instance.interceptors.request.use((config) => {
  // config.headers.Authorization = ''
  return config
})

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    let payload: string = error?.message
    try {
      payload = JSON.stringify(error.response?.data || error)
    } catch (err) {
      //
    }

    Sentry.captureException(payload, {
      extra: {
        place: 'internal-api',
        stack: error.stack,
        status: error.status || error.response?.status,
        message: error.message,
        code: error.code,
      },
    })
    return Promise.reject(error)
  },
)
