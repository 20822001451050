import { useCallback, useMemo } from 'react'
// import { signOut, useSession } from 'next-auth/react'
import { useUser } from '@auth0/nextjs-auth0/client'
import { useRouter } from 'next/router'
import { isEmpty } from 'lodash'
import { CurrentUser } from '@/server/services/Auth0/Auth0.types'
import { AUTH0_PATH, PAGE } from '../constants/pages'
import { CurrentSession } from './useNextAuth.utils'

type Status = 'loading' | 'authenticated' | 'unauthenticated'

export const useNextAuth = () => {
  // const { data: session, status } = useSession()
  const router = useRouter()
  const { user, error, isLoading } = useUser()
  const status = useMemo<Status>(() => {
    if (isLoading) return 'loading'
    return user ? 'authenticated' : 'unauthenticated'
  }, [isLoading, user])

  // console.log({ user, error, isLoading })

  // const logout = useCallback(async () => {
  //   // router.push(AUTH0_PATH.LOGOUT)
  //   // router.push('https://mantisanalytics.us.auth0.com/v2/logout')
  //   router.push(`https://mantisanalytics.us.auth0.com/v2/logout?returnTo=${window.location.origin}`)
  // }, [router])

  return {
    // isLoggedIn: !isEmpty(session?.user),
    // session: session as CurrentSession | undefined,
    // status,
    // logout,
    isLoggedIn: !isEmpty(user),
    session: user ? ({ user } as { user: CurrentUser }) : undefined,
    status,
    // logout,
  }
}
