import { AnalyticReportItem, KasViolationItem } from '@/server/services/GoogleBigQuery/GoogleBigQuery.types'
import { GoogleDriveFile, UploadFileResult } from '@/server/services/GoogleDrive/GoogleDrive.types'
import { API, instance } from './instance'

export const electionsIntegrity = {
  getAnalyticReports: () => instance.get<AnalyticReportItem[]>(API.ELECTIONS_INTEGRITY.REPORTS),

  getFilesList: () => instance.get<GoogleDriveFile[]>(API.ELECTIONS_INTEGRITY.LIST),

  uploadFile: (data: { file: File }) => {
    const formData = new FormData()
    formData.append('file', data.file)

    return instance.post<UploadFileResult>(API.ELECTIONS_INTEGRITY.UPLOAD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  createViolation: (
    data: { file: File } & Omit<
      KasViolationItem,
      'ViolationID' | 'ViolationDateTime' | 'FileID' | 'FileHyperlink' | 'FileName'
    >,
  ) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      formData.append(key, (data as any)[key])
    })

    return instance.post<KasViolationItem>(API.ELECTIONS_INTEGRITY.CREATE_VIOLATION, formData, {
      timeout: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
