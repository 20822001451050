import { isEmpty } from 'lodash'
import { CurrentUser } from '@/server/services/Auth0/Auth0.types'

// import { CognitoUserGroup } from '@/server/services/CognitoUserHelper/CognitoUserHelper.types'

export class CurrentUserModel {
  user: Partial<CurrentUser>
  name: CurrentUser['name']
  email: CurrentUser['email']
  groups: string[]

  constructor(user: Partial<CurrentUser> = {}) {
    this.user = user
    this.name = user?.nickname || user?.name || ''
    this.email = user?.email || ''
    this.groups = []
  }

  // TODO: Fix and update after google-auth done
  isGuestUser = () => false
  isMantisUser = () => true
  hasAccessToGroup = () => true
  // isGuestUser = () => isEmpty(this.user.groups)
  // isMantisUser = () => this.user.groups?.includes(CognitoUserGroup.MantisTeam)
  // hasAccessToGroup = (group: CognitoUserGroup) => this.user.groups?.includes(group)
}
