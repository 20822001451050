import { CurrentUser } from '@/server/services/Auth0/Auth0.types'

const PERMISSION_USERS = process.env.NEXT_PUBLIC_KAS_HUB_PERMISSION_USERS

const isMatchNames = (name: string) => (PERMISSION_USERS || '').match(name)

/**
 * KAS. Elections Integrity Hub
 * Temp solution
 */
export const KasValidation = {
  isKasUser: (user: Partial<CurrentUser>) => {
    return ['kas', 'hub'].includes(user.nickname!)
  },

  isPageKasAvailable: (user: Partial<CurrentUser>) => {
    const hasKasAccess = !!isMatchNames(user.name!) || !!isMatchNames(user.nickname!)
    return hasKasAccess
  },
}
